import {
  DownCircleFilled,
  DownCircleOutlined,
  RightCircleFilled,
  RightCircleOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useState } from "react";
import "./CollapsedContent.css";
export const CollapsedContent = (props) => {
  const { title } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);
  return (
    <div className="collapsed-content-widget">
      <div>
        <Row
          className="collapsed-content-title custom-button"
          onClick={() => setIsCollapsed(!isCollapsed)}
          align={"middle"}
          gutter={8}
          style={{ backgroundColor: "var(--whiteColor)" }}
          wrap={false}
        >
          <Col>
            {isCollapsed ? (
              <RightCircleFilled className={"collapsed-content-icon"} />
            ) : (
              <DownCircleFilled className={"collapsed-content-icon"} />
            )}
          </Col>
          <Col>{title}</Col>
        </Row>
      </div>

      {!isCollapsed && (
        <div className="collapsed-content-description"> {props.children}</div>
      )}
    </div>
  );
};

export default CollapsedContent;
