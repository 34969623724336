import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { message, Modal, Col, Input, Row, Tooltip, Radio, Spin, DatePicker } from "antd";

import "./MultiPrintDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import moment from "moment"
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
import RecipeExtraItems from '../../RecipeExtraItems/RecipeExtraItems';
import ExtraOptionItems from '../../ExtraOptionItems/ExtraOptionItems';
import { showAddBaseUnitDialog } from './AddBaseUnitDialog/showAddBaseUnitDialog';
import ProductTypeSelector from 'components/ProductPage/ProductTypeSelector/ProductTypeSelector';

function MultiPrintDialog(props) {
  const { show, proceed, info, title, isEditing } = props

  const [data, setData] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [nameList, setNameList] = useState([]);
  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  useEffect(() => {

  }, [])

  useEffect(() => {

  }, [info]);


  const validateFields = async () => {

    var errorList = Array();
    info.cartList.forEach(item => {
      errorList = isEmpty(data, item.id, errorList);
    });
    setErrorField([...errorList]);
    return errorList.length == 0;
  };

  const addPeople = async () => {
    var res = await showAddBaseUnitDialog({
      oldList: nameList,
    });
    if (res == null) return;

    let updateData = [res['addedItem'], ...nameList];
    setNameList([...updateData]);
  }
  const itemUI = info.cartList == undefined || info.cartList.length == 0 ? <div>You don't have any Item list</div> : info.cartList.map((item, index) => {
    return (
      <div key={index}>
        <div style={{ backgroundColor: item.fill_color, "color": "white", padding: 5 }}>
          <Row gutter={4}>
            <Col>
              <span className='bold font-14'>{item.enter_qty}x</span>
            </Col>
            <Col>
              <div className='item-name bold font-14'>{item.name}</div>
              <div className='font-15'>${parseFloat(item.total_price).toFixed(2)}&nbsp;{info.payment_currency}</div>
            </Col>
          </Row>
          <div>
            <RecipeExtraItems
              optionItemString={item.recipe_extra}
            />
          </div>
          <div>
            <ExtraOptionItems
              optionItemString={item.extraOptionList}
            />
          </div>
          <div className='item-note font-14'>{item.note}</div>
        </div>
        <ProductTypeSelector
          placeholder={"Select Name"}
          field={`${item.id}`}
          productInfo={data}
          setProductInfo={setData}
          list={nameList}
          displayField="name"
          idField="id"
          errorField={errorField}
          setErrorField={setErrorField}
          onChangeHandle={(value) => {
          }}
        />
        {info.cartList.length != index + 1 && <div className='item-body-bottom'></div>}
      </div>
    );
  });

  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-multi-print-dlg"
      onOk={async () => {

        if ((await validateFields()) === false) return;

        var assignList = Object.keys(data).map((key) =>{return { recipe_id: key, person_name_id: data[key]}});        
        let group_data = groupBy(assignList, 'person_name_id')
        var result = []
        Object.keys(group_data).forEach(function (key) {
          result.push(group_data[key]);
        });

        proceed({
          group_id_list : result,
          nameList : nameList
        });
      }}
    >
      <Spin spinning={isBusy}>
        <Row justify={'end'}>
          <div style={{ padding: 8 }}>
            <Tooltip title={"Click to add new one."}>
              <CommonButton
                customClass={"product-upload-basic-unit-add-button"}
                onClick={() => {
                  addPeople();
                }}
              >
                Add
              </CommonButton>
            </Tooltip>
          </div>
        </Row>
        <div className='item-multi-print-body'>
          {itemUI}
        </div>
      </Spin>
    </Modal>
  );
}

export default confirmable(MultiPrintDialog)
