import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  SettingOutlined, ShoppingCartOutlined, UserOutlined,
} from "@ant-design/icons";
import { Col, Row, Badge, Dropdown, Space, Menu } from "antd";
import ButtonWithIcon from "components/ButtonWidgets/ButtonWithIcon/ButtonWithIcon";
import { IconWidget } from "components/IconWidget/IconWidget";
import { MENU_ITEMS } from "navigation/CONSTANTS";
import {
  ROUTE_LOGIN,
} from "navigation/CONSTANTS";
import { ChangeMenuCollapsed, UpdateCurrentRoot } from "redux/actions/appActions";
import collapseIcon from '../../assets/icons/ic_collapse.svg'
import "./TopbarLayout.css";

function TopbarLayout(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userDataStore = useSelector(x => x.userDataStore);
  const appDataStore = useSelector(x => x.appDataStore);
  const [currentPathTitle, setCurrentPathTitle] = useState("Dashboard")
  useEffect(() => {
    const same = MENU_ITEMS.filter(item => item.route == currentRoute);
    let name = same.length === 0 ? "Kalae Cafe Chef" : same[0].title;

    setCurrentPathTitle(name)
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, [history.location.pathname]);

  const { collapsed, width, currentRoute } = appDataStore;
  const userInfo = userDataStore.user;


  //   const menuItems = OPTION_ITEMS.map((info, index) => {
  //     return <div>
  //       <Link className="menu-widget-item" key={info.route} to={info.route} onClick={() => { console.log("ss:", info) }}>
  //         {info.icon} {info.title}
  //       </Link>
  //     </div>
  // })

  const menuWidget = () => {
    return (
      <div></div>
    );
  };

  if (width <= 1024) {
    return <div>
      <Row align={"center"}>
        <div style={{paddingTop:10}}>
          <img
            style={{ maxWidth: "80%", maxHeight: 80 }}
            src={'/assets/images/logo.png'}
            alt="KalaeCafe"
          />
          <span className="txt-top-title">{currentPathTitle}</span>
        </div>
      </Row>

      <Row align={"center"} >
        <div style={{paddingTop:10}}>
          <span className="txt-top-welcome">Welcome Back!</span> <span className="txt-top-name">{userInfo.first_name}</span>
        </div>
        <Row className="top-setting-bg" justify={'space-between'} align={'middle'}>
          <Col className="gutter-row">
            <div><img style={{ cursor: "pointer" }} src="/assets/images/user_profile.png" alt="" /><span className="txt-top-logout" onClick={() => { history.push(ROUTE_LOGIN) }}>Log out</span></div>
          </Col>
        </Row>
      </Row>
      <div>{props.children}</div>
    </div>
  }
  return (
    <div>
      <div className="top-navbar-layout">
        <div className="top-navbar-header">
          <Row align={"middle"} gutter={16} justify={"space-between"}>
            <Col>
              <img
                style={{ maxWidth: "80%", maxHeight: 80 }}
                src={'/assets/images/logo.png'}
                alt="KalaeCafe"
              />
              <span className="txt-top-title">{currentPathTitle}</span>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <Row align={"middle"} >
                <Col align={"middle"} >
                  <span className="txt-top-welcome">Welcome Back!</span> <span className="txt-top-name">{userInfo.first_name}</span>
                </Col>
                <Col>
                  <Row className="top-setting-bg" justify={'space-between'} align={'middle'}>
                    <Col className="gutter-row">
                      <div>
                        <img className="user-profile-image" src={userInfo.image_url == null || userInfo.image_url == "" ? "/assets/images/user_profile.png" : userInfo.image_url} alt="" />
                        <span className="txt-top-logout" onClick={() => { history.push(ROUTE_LOGIN) }}>Log out</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

            </Col>

          </Row>
        </div>
      </div>
      <div>{props.children}</div>
    </div>
  );
}

export default withRouter(TopbarLayout);
