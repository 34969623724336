// @flow strict

import React, { useState } from "react";
import { Divider, Input, Spin } from "antd";
import "./ContactusPage.css";
import { message } from "antd";
import { apiContactUs } from "services/contactService";
const { TextArea } = Input;
function ContactusPage(props) {
  const [contactInfo, setContactInfo] = useState({
    full_name: "",
    contact_info: "",
    subject: "",
    message: "",
  });
  const [isBusy, setIsBusy] = useState(false);
  const checkFields = (field) => {
    const value = contactInfo[field];
    if (value === undefined || value === "") {
      message.error(field + " is requred.");
      return false;
    } else {
      return true;
    }
  };
  const onClickContactUs = () => {
    if (checkFields("full_name") === false) return;
    if (checkFields("contact_info") === false) return;
    if (checkFields("subject") === false) return;
    if (checkFields("message") === false) return;

    setIsBusy(true);
    apiContactUs(contactInfo)
      .then((res) => {
        setIsBusy(false);
        message.success("Your message has been sent.");
        setContactInfo({
          full_name: "",
          contact_info: "",
          subject: "",
          message: "",
        });
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  return (
    <div style={{ textAlign: "center", width: "100%" }}>
      <div className="contact-us-page">
        <Spin spinning={isBusy}>
          <div className="contact-us-page-title">Send a message</div>
          <div>
            <Input
              placeholder="Full name"
              className="contactus-input contactus-page-fullname"
              value={contactInfo.full_name}
              onChange={(e) =>
                setContactInfo({ ...contactInfo, full_name: e.target.value })
              }
            />
          </div>
          <div>
            <Input
              placeholder="Email address or phone number"
              className="contactus-input contactus-page-email"
              value={contactInfo.contact_info}
              onChange={(e) =>
                setContactInfo({ ...contactInfo, contact_info: e.target.value })
              }
            />
          </div>
          <div>
            <Input
              placeholder="Subject"
              className="contactus-input contactus-page-subject"
              value={contactInfo.subject}
              onChange={(e) =>
                setContactInfo({ ...contactInfo, subject: e.target.value })
              }
            />
          </div>
          <div>
            <TextArea
              placeholder="Message"
              className="contactus-input contactus-page-message"
              rows={7}
              value={contactInfo.message}
              onChange={(e) =>
                setContactInfo({ ...contactInfo, message: e.target.value })
              }
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <div
              className="contact-us-button custom-button"
              onClick={onClickContactUs}
            >
              Send message
            </div>
          </div>
        </Spin>
        <div className={"get-in-touch-container"}>
          <div className={"get-in-touch-title"}>Get in touch</div>
          <div className={"get-in-touch-description"}>
            We would like to hear from you
          </div>
          <Divider />
          <div className={"get-in-touch-sub-title"}>Contact information</div>
          <a
            className={"get-in-touch-description custom-button"}
            href={`mailto://info@kalaecafe.com}`}
          >
            <i className="fas fa-envelope contactus-icon" />
            Email: info@kalaecafe.com
          </a>
          <Divider />
          <div className={"get-in-touch-sub-title"}>Business Hours</div>
          <div className={"get-in-touch-description"}>
            <i className="far fa-clock contactus-icon" />
            Monday - Friday - 8am to 5pm (GMT +2)
          </div>
          <div className={"get-in-touch-description"}>
            <i
              className="far fa-clock contactus-icon"
              style={{ color: "var(--blackColor)" }}
            />
            Saturday - Closed
          </div>
          <div className={"get-in-touch-description"}>
            <i
              className="far fa-clock contactus-icon"
              style={{ color: "var(--blackColor)" }}
            />
            Sunday - Closed
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactusPage;
