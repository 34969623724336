import TempTopMenuWidget from "layouts/TempTopMenuWidget/TempTopMenuWidget";
import React from "react";
import { MetaTags } from "react-meta-tags";
import ContactusPage from "./ContactusPage";

const ContactusUnregisterPage = () => {
  
  return (
    <div>
      <TempTopMenuWidget>
      <MetaTags>
        <title>Spring | Contact Us</title>
        <meta
          name="description"
          content="Welcome to Spring. Read the terms of services  to understand how they apply to your use of our website."
        />
        <meta name="keywords" content="Best Deals" />
      </MetaTags>
        <div style={{paddingTop: 70}}>
        <ContactusPage />
        </div>
      </TempTopMenuWidget>
    </div>
  );
};

export default ContactusUnregisterPage;
