// @flow strict

import CollapsedContent from "components/CollapsedContent/CollapsedContent";
import TempTopMenuWidget from "layouts/TempTopMenuWidget/TempTopMenuWidget";
import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import "./TermsPage.css";
function TermsPage(props) {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, []);
  const mailToWidget = (emailAddress) => (
    <a href={`mailto://${emailAddress}`}>
      <font>
        <u>{emailAddress}</u>
      </font>
    </a>
  );
  return (
    <TempTopMenuWidget>
      <MetaTags>
        <title>KalaeCafe | Terms of Service | Best Deals</title>
        <meta
          name="description"
          content="Welcome to KalaeCafe. Read the terms of services  to understand how they apply to your use of our website."
        />
        <meta name="keywords" content="Best Deals" />
      </MetaTags>
      <div>
        <div className="terms-page">
          <div className="terms-page-title">
            TERMS AND CONDITIONS OF USE - Welcome to{" "}
            <a href="https://www.kalaecafe.com">
              <font>
                <u>www.kalaecafe.com</u>
              </font>
            </a>{" "}
          </div>
          <div className="terms-page-description">
            Term of message {" "}
            <a href="https://www.kalaecafe.com">
              <font>
                <u>www.kalaecafe.com</u>
              </font>
            </a>{" "}
            message {mailToWidget("info@kalaecafe.com")}.
          </div>
          <div style={{ marginTop: 50 }}>
            <CollapsedContent title={"1. General Terms and Conditions"}>
              <div className="terms-body">
                <p>
                  {" "}
                  The{" "}
                  <a href="https://www.kalaecafe.com">
                    <font>
                      <u>www.kalaecafe.comm</u>
                    </font>
                  </a>{" "}
                  General policy.
                </p>
              </div>
              <div className="terms-card">
                <div className="terms-header">
                  <h3>1.1 Applicability of Terms and Conditions</h3>
                </div>
                <div className="terms-body">
                  <p>
                    {" "}
                    policy {mailToWidget("info@kalaecafe.com")}.
                  </p>
                </div>
              </div>

              <div className="terms-card">
                <div className="terms-header">
                  <h3>1.2 Changes to Terms and Conditions</h3>
                </div>
                <div className="terms-body">
                  <p>
                    {" "}
                    policy
                  </p>
                </div>
              </div>


            </CollapsedContent>
            
          </div>
        </div>
      </div>
    </TempTopMenuWidget>
  );
}

export default TermsPage;
