import Icon, { SettingOutlined, ShoppingCartOutlined, UserOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { ReactComponent as dashboardIcon } from '../assets/icons/ic_dashboard.svg';
import { ReactComponent as storeIcon } from '../assets/icons/ic_store.svg';
import { ReactComponent as analyticsIcon } from '../assets/icons/ic_analytics.svg';
import { ReactComponent as promotionIcon } from '../assets/icons/ic_promotion.svg';
import { ReactComponent as ingredientIcon } from '../assets/icons/ic_ingredient.svg';
import { ReactComponent as bestdealIcon } from '../assets/icons/ic_bestdeal.svg';
import { ReactComponent as productIcon } from '../assets/icons/ic_product.svg';
import { ReactComponent as postIcon } from '../assets/icons/ic_post.svg';
import { ReactComponent as categoryIcon } from '../assets/icons/ic_category.svg';
import { ReactComponent as locationIcon } from '../assets/icons/ic_location.svg';
import { ReactComponent as unitIcon } from '../assets/icons/ic_unit.svg';

export const ROOT = "/";
export const ROUTE_HOME = "/home";
export const ROUTE_LOGIN = "/login";
export const ROUTE_DASHBOARD = "/dashboard";

export const ROUTE_CONTACT_US = "/contact-us";
export const ROUTE_CONTACT_US_UNREGISTER = "/contact-us/unregister";

export const ROUTE_PRIVACY_POLICY = "/privacy-policy";
export const ROUTE_TERMS_OF_SERVICE = "/terms-of-service";
export const ROUTE_ABOUT_US = "/about-us";

export const ROUTE_SALES = "/sales";
export const ROUTE_SALES_SUCCESS = "/sales/success";

export const MENU_ITEMS = [
    // { route: ROUTE_DASHBOARD, icon: <Icon component={dashboardIcon} className="menu-item-icon" />, title: "Overview", type:"sidebar" },
    //  { route: ROUTE_PART, icon: <Icon component={storeIcon} className="menu-item-icon" />, title: "Part Info List", type:"sidebar"},
    // { route: ROUTE_INVENTORY_NAME, icon: <Icon component={analyticsIcon} className="menu-item-icon" />, title: "Inventory History", type:"sidebar", 
    //     // submenu:[
    //     //     { route: ROUTE_INVENTORY_NAME, icon: <Icon component={postIcon} className="menu-item-icon" />, title: "By Name", type:"sidebar"},        
    //     //     { route: ROUTE_INVENTORY_CATEGORY, icon: <Icon component={productIcon} className="menu-item-icon" />, title: "By Category", type:"sidebar"},
    //     //     { route: ROUTE_INVENTORY_LOCATION, icon: <Icon component={promotionIcon} className="menu-item-icon" />, title: "By Location", type:"sidebar"},        
    //     // ]
    // },
    //  { route: ROUTE_RECOMMEND, icon: <Icon component={promotionIcon} className="menu-item-icon" />, title: "Recommend Spring", type:"sidebar"},
    //  { route: ROUTE_INTERNAL_USE, icon: <Icon component={productIcon} className="menu-item-icon" />, title: "Internal Use", type:"sidebar"},
    //  { route: ROUTE_PROFILE, icon: <Icon component={analyticsIcon} className="menu-item-icon" />, title: "Profile Setting", type:"sidebar"},
    // { route: ROUTE_RECIPE_LIST, icon: <Icon component={promotionIcon} className="menu-item-icon" />, title: "Recipe List", type:"sidebar"},
    // { route: ROUTE_ORDER, icon: <Icon component={promotionIcon} className="menu-item-icon" />, title: "Order List", type:"sidebar"},
    // { route: ROUTE_PAY_HISTORY, icon: <Icon component={ingredientIcon} className="menu-item-icon" />, title: "Payment History", type:"sidebar"},
    // { route: ROUTE_CATEGORY, icon: <Icon component={categoryIcon} className="menu-item-icon" />, title: "Category List", type:"sidebar"},
    // { route: ROUTE_LOCATION, icon: <Icon component={locationIcon} className="menu-item-icon" />, title: "Location List", type:"sidebar"},
    // { route: ROUTE_UNIT, icon: <Icon component={unitIcon} className="menu-item-icon" />, title: "Unit List", type:"sidebar"},
]
