import React from "react";
import { message, Tabs, DatePicker, Row, Col } from 'antd';
import type { PaginationProps } from 'antd';
import { Pagination } from 'antd';
import "./DeliveredOrder.css";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import { useState } from "react";
import { useEffect } from "react";
import { apiGetOrderListDelivered, apiPaymentConfirmOrder } from "services/orderService";
import { SOCKET_EVENT_S_PAY_CONFIRM } from "services/CONSTANTS";
import SaleItemWidget from "../SaleItemWidget/SaleItemWidget";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import PrintView from "components/PrintView/PrintView";
import moment from 'moment';
const { RangePicker } = DatePicker;

function DeliveredOrder(props) {

    const { ownCommonData, setOwnCommonData, setIsBusy, mSocket, settingData } = props;
    const [searchList, setSearchList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    const setupSearchList = (dataList) => {
        let group_data = groupBy(dataList, 'table_name')
        var result = []
        Object.keys(group_data).forEach(function (key) {
            result.push(group_data[key]);
        });
        result.map((info, index) => {
            info.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.add_timestamp) - new Date(b.add_timestamp);
            });
        })
        result.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            try {
                return new Date(a[0].add_timestamp) - new Date(b[0].add_timestamp);
            } catch (error) {
                return 1
            }
        });
        setSearchList(result)
    }
    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
        setPageSize(pageSize);
        setCurrentPage(current);
    };
    const onChange: PaginationProps['onChange'] = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        setIsBusy(true)
        apiGetOrderListDelivered(currentPage, pageSize, startDate, endDate)
            .then((data) => {
                setIsBusy(false)
                setOwnCommonData({ ...ownCommonData, deliveredOrderList: data.deliveredOrderList })
                setTotalCount(data.total_count)
            })
            .catch((err) => {
                setIsBusy(false)
                message.error("Sorry! " + err);
            });
    }, [pageSize, currentPage, startDate, endDate]);

    useEffect(() => {
        if (ownCommonData.deliveredOrderList !== undefined) {
            setupSearchList(ownCommonData.deliveredOrderList)
        }
    }, [ownCommonData]);



    const handleSearch = (searchText) => {
        const tmpList = ownCommonData.deliveredOrderList.filter((info) => info.customer_name.toLowerCase().includes(searchText.toLowerCase()) || info.table_name.toLowerCase().includes(searchText.toLowerCase()))
        setupSearchList(tmpList)
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setupSearchList(ownCommonData.deliveredOrderList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const paymentConfirmAction = async (record) => {
        var res = await showConfirmDlgWidget({ title: "Payment Confirmation", content: "Payment is not completed, yet. Please check it and once it is finished, click 'Yes' button." });
        if (res == false) return;
        setIsBusy(true);
        apiPaymentConfirmOrder(record)
            .then((data) => {
                setIsBusy(false)
                message.success("Payment Confirm Success")
                record.payment_status = 'Completed';
                updateData(record)
                mSocket.emit(SOCKET_EVENT_S_PAY_CONFIRM, record)
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Payment Confirm Error. " + err);
            });
    }
    const printAction = async (record) => {

    }

    const updateData = (record) => {
        if (ownCommonData.deliveredOrderList === undefined) return
        let tmpList = [...ownCommonData.deliveredOrderList]
        tmpList = tmpList.filter((x) => x.id !== record.id)

        //ownCommonData.deliveredOrderList.splice(ownCommonData.deliveredOrderList.findIndex(e => e.id === record.id), 1);

        // let deliveredOrderList = tmpList.concat(record)
        // setOwnCommonData({ ...ownCommonData, deliveredOrderList: deliveredOrderList })
        setupSearchList(tmpList)
    }

    const handleDateFilter = (startDate, endDate) => {
        setCurrentPage(1)
        setStartDate(startDate)
        setEndDate(endDate)

    }

    const cleanRecords = async () => {
        var res = await showConfirmDlgWidget({ title: "Clean Confirmation", content: "Are you sure to clean all delivered items data? To show again, you can reload website" });
        if (res == false) return;
        setOwnCommonData({ ...ownCommonData, deliveredOrderList: [] })
        setupSearchList([])
    }

    const subItemUi = (list) => {
        let tmpList = list
        // tmpList.sort(function(a,b){
        //     // Turn your strings into dates, and then subtract them
        //     // to get a value that is either negative, positive, or zero.
        //     return new Date(a.add_timestamp) - new Date(b.add_timestamp);
        //   });
        return tmpList.map((info, index) => {
            return <div style={{ marginTop: 10 }} key={index}>
                <SaleItemWidget
                    info={info}
                    paymentConfirmAction={paymentConfirmAction}
                    printAction={printAction}
                    settingData={settingData}
                    type={"2"}
                />
            </div>
        });
    }
    return (
        <div>
            <Row>
                <Col>
                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>Date Filter Period : &nbsp;&nbsp;
                        <RangePicker
                            defaultValue={[moment(startDate), moment(endDate)]}
                            size={'default'}
                            onChange={(e, date) => {
                                if (date == null) {
                                    handleDateFilter("", "")
                                } else {
                                    handleDateFilter(date[0], date[1])
                                }
                            }} />
                    </div>
                </Col>
                <Col flex={'auto'}>
                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                        <TitleSearch
                            placeholder={"Search by Customer Name or Table Number"}
                            onSearch={handleSearch}
                            onChange={handleChange}
                            onPressEnter={handleSearch}
                        />
                    </div>
                </Col>
                <Col>
                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                        <CommonButton onClick={() => {
                            cleanRecords()
                        }}>Clean</CommonButton>
                    </div>
                </Col>
                <Col>
                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                        <PrintView
                            dataList={searchList}
                            settingData={settingData}
                        />
                    </div>
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col>
                    <Pagination
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChange}
                        defaultCurrent={1}
                        current={currentPage}
                        total={totalCount}
                        pageSize={pageSize}
                    />
                </Col>
            </Row>
            <div className="printView">
                <div className="parent-table">
                    {searchList == undefined || searchList.length == 0 ? <div style={{ margin: "auto" }}>You don't have any Item list</div> : searchList.map((info, index) => {
                        return <div className="child-table" key={index}>{subItemUi(info)}</div>
                    })}
                </div>
            </div>

        </div>
    );
}

export default DeliveredOrder;
