import { Input, message } from "antd";
import RadiusModal from "components/RadiusModal/RadiusModal";
import React, { useState } from "react";
import { confirmable } from "react-confirm";
import "./TextInputDialog.css";
const TextInputDialog = (props) => {
  const { show, proceed } = props;
  const { title, content, isLongText, placeholder } = props;
  const [value, setValue] = useState(content == undefined ? "" : content);
  const p =
    placeholder == undefined ? "Please type your own value" : placeholder;
  return (
    <RadiusModal
      show={show}
      title={title}
      isConfirmDlg={false}
      onClickOK={() => {
        if (value == "") {
          message.error("The value can not be empty.");
          return;
        }
        proceed(value);
      }}
      onClickCancel={() => {
        proceed(false);
      }}
    >
      {isLongText ? (
        <Input.TextArea
          className="text-input-dialog-input"
          placeholder={p}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          rows={5}
        />
      ) : (
        <Input
          className="text-input-dialog-input"
          placeholder={p}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
    </RadiusModal>
  );
};

export default confirmable(TextInputDialog);
