import React from "react";
import { message, Tabs, DatePicker, Row, Col } from 'antd';
import type { PaginationProps } from 'antd';
import { Pagination } from 'antd';
import "./UpcomingOrder.css";
import TableWidget from "./TableWidget/TableWidget";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import { useState } from "react";
import { useEffect } from "react";
import { apiApproveOrder, apiCancelOrder, apiGetOrderListUpcoming, apiPaymentConfirmOrder } from "services/orderService";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
import { SOCKET_EVENT_S_APPROVED_ORDER, SOCKET_EVENT_S_CANCELLED_ORDER } from "services/CONSTANTS";
import SaleItemWidget from "../SaleItemWidget/SaleItemWidget";
import moment from 'moment';
const { RangePicker } = DatePicker;

function UpcomingOrder(props) {
    const { ownCommonData, setOwnCommonData, setIsBusy, mSocket, playSoundAction } = props;
    const [searchList, setSearchList] = useState([]);

    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const setupSearchList = (dataList) => {
        dataList.map((item, index) => {
            return item["orderIndex"] = index + 1;
        });
        let group_data = groupBy(dataList, 'table_name')
        var result = []
        Object.keys(group_data).forEach(function (key) {
            result.push(group_data[key]);
        });
        result.map((info, index) => {
            info.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.add_timestamp) - new Date(b.add_timestamp);
            });
        })
        result.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            try {
                return new Date(a[0].add_timestamp) - new Date(b[0].add_timestamp);
            } catch (error) {
                return 1
            }
        });
        setSearchList(result)
    }
    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
        setPageSize(pageSize);
        setCurrentPage(current);
    };
    const onChange: PaginationProps['onChange'] = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        setIsBusy(true)
        apiGetOrderListUpcoming(currentPage, pageSize, startDate, endDate)
            .then((data) => {
                setIsBusy(false)
                if (data.upcomingOrderList === undefined) {
                    setOwnCommonData({ ...ownCommonData, upcomingOrderList: [] })
                } else {
                    setOwnCommonData({ ...ownCommonData, upcomingOrderList: data.upcomingOrderList })
                }
                setTotalCount(data.total_count)
            })
            .catch((err) => {
                setIsBusy(false)
                message.error("Sorry! " + err);
            });
    }, [pageSize, currentPage, startDate, endDate]);

    useEffect(() => {
        if (ownCommonData.upcomingOrderList !== undefined) {
            setupSearchList(ownCommonData.upcomingOrderList)
        }
    }, [ownCommonData]);

    const handleSearch = (searchText) => {
        const tmpList = ownCommonData.upcomingOrderList.filter((info) => info.customer_name.toLowerCase().includes(searchText.toLowerCase()) || info.table_name.toLowerCase().includes(searchText.toLowerCase()))
        setupSearchList(tmpList)
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setupSearchList(ownCommonData.upcomingOrderList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const approveAction = async (record) => {
        var res = await showConfirmDlgWidget({ title: "Approve Confirmation", content: "Are you sure to approve this order?" });
        if (res == false) return;
        if (record.payment_status === null || record.payment_status === undefined || record.payment_status === '') {
            var res_1 = await showConfirmDlgWidget({ title: "Payment Confirm to Approve this order", content: "Payment for this order is completed?" });
            if (res_1 == false) {
                var res = await showConfirmDlgWidget({ title: "Payment Confirm to Approve this order", content: "Payment is not completed for order.  After deliver is finished, you can check payament, again. Will you continue to approve?" });
                if (res == false) return;
                handleApproveAction(record)
            }
            else {
                paymentConfirmAction(record)
            }

        }
        else {
            handleApproveAction(record)
        }
    }

    const cancelAction = async (record) => {
        var res = await showConfirmDlgWidget({ title: "Cancel Confirmation", content: "Are you sure to cancel this order?" });
        if (res == false) return;
        setIsBusy(true);
        apiCancelOrder(record.id)
            .then((data) => {
                setIsBusy(false)
                if (ownCommonData.upcomingOrderList === undefined) return
                ownCommonData.upcomingOrderList.splice(ownCommonData.upcomingOrderList.findIndex(e => e.id === data.id), 1);
                setOwnCommonData({ ...ownCommonData })
                setupSearchList(ownCommonData.upcomingOrderList)
                mSocket.emit(SOCKET_EVENT_S_CANCELLED_ORDER, data)
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Cancel Error. " + err);
            });
    }

    const paymentConfirmAction = async (record) => {

        setIsBusy(true);
        apiPaymentConfirmOrder(record)
            .then((data) => {
                setIsBusy(false)
                message.success("Payment Confirm Success")
                record.payment_status = 'Completed';

                ownCommonData.upcomingOrderList.splice(ownCommonData.upcomingOrderList.findIndex(e => e.id === record.id), 1);
                let upcomingOrderList = ownCommonData.upcomingOrderList.concat(record)
                setOwnCommonData({ ...ownCommonData, upcomingOrderList: upcomingOrderList })
                setupSearchList(upcomingOrderList)
                handleApproveAction(record)
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Payment Confirm Error. " + err);
            });
    }
    const handleApproveAction = (record) => {
        setIsBusy(true);
        apiApproveOrder(record.id)
            .then((data) => {
                setIsBusy(false)
                updateData(data)
                mSocket.emit(SOCKET_EVENT_S_APPROVED_ORDER, data)
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Error. " + err);
            });
    }

    const updateData = (data) => {
        if (ownCommonData.upcomingOrderList === undefined) return
        let tmpList = [...ownCommonData.upcomingOrderList]
        tmpList = tmpList.filter((x)=>x.id !== data.id)
        ownCommonData.upcomingOrderList.splice(ownCommonData.upcomingOrderList.findIndex(e => e.id === data.id), 1);
        let approvedOrderList = ownCommonData.approvedOrderList.concat(data)
        setOwnCommonData({ ...ownCommonData, approvedOrderList: approvedOrderList })
        setupSearchList(tmpList)
        playSoundAction()
    }

    const handleDateFilter = (startDate, endDate) => {
        setCurrentPage(1)
        setStartDate(startDate)
        setEndDate(endDate)
    }

    const itemUI = searchList == undefined || searchList.length == 0 ? <div style={{ margin: "auto" }}>You don't have any Item list</div> : searchList.map((info, index) => {
        return (
            <Col xs={24} sm={12} md={8} lg={6} xl={6} key={index}>
                <SaleItemWidget
                    info={info}
                    approveAction={approveAction}
                    cancelAction={cancelAction}
                    type={"0"}
                />
            </Col>
        );
    });

    const subItemUi = (list) => {
        let tmpList = list
        return tmpList.map((info, index) => {
            return <div style={{ marginTop: 10 }} key={index}>
                <SaleItemWidget
                    info={info}
                    approveAction={approveAction}
                    cancelAction={cancelAction}
                    type={"0"}
                />
            </div>
        });
    }
    return (
        <div>
            <Row>
                <Col>
                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>Date Filter Period : &nbsp;&nbsp;
                        <RangePicker
                            // defaultValue={[moment(startDate), moment(endDate)]}
                            //allowClear={false}
                            size={'default'}
                            onChange={(e, date) => {
                                if (date == null) {
                                    handleDateFilter("", "")
                                } else {
                                    handleDateFilter(date[0], date[1])
                                }

                            }} />
                    </div>
                </Col>
                <Col flex={'auto'}>
                    <div style={{ fontFamily: 'mediumFont', padding: 20 }}>
                        <TitleSearch
                            placeholder={"Search by Customer Name or Table Number"}
                            onSearch={handleSearch}
                            onChange={handleChange}
                            onPressEnter={handleSearch}
                        />
                    </div>
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col>
                    <Pagination
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChange}
                        defaultCurrent={1}
                        current={currentPage}                        
                        total={totalCount}
                        pageSize={pageSize}
                    />
                </Col>
            </Row>
            <div className="parent-table">
                {searchList == undefined || searchList.length == 0 ? <div style={{ margin: "auto" }}>You don't have any Item list</div> : searchList.map((info, index) => {
                    return <div className="child-table" key={index}>{subItemUi(info)}</div>
                })}
            </div>
        </div>
    );
}

export default UpcomingOrder;
