// @flow strict

import { Col, Input, message, Row, Select, Spin } from "antd";
import React, { useState } from "react";
import "./InputLocation.css";
import AsyncSelect from "react-select/async";
import {
  getAddresses,
  getAddressFromCoord,
} from "services/commonService";
import { AimOutlined } from "@ant-design/icons";
import TextButton from "components/ButtonWidgets/TextButton/TextButton";
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
};
function InputLocation(props) {
  const { location, setLocation } = props;
  const { address } = props;
  const [inputValue, setInputValue] = useState("");
  const promiseOptions = (keyword) =>
    new Promise((resolve, reject) => {
      if (keyword === "" || keyword === undefined) {
        reject();
      } else {
        
      }
    });


  return (
    <div className="input-location-widget">
      <Row align={"middle"}>
        <Col flex={"auto"}>
          <AsyncSelect
            cacheOptions
            styles={customStyles}
            defaultOptions
            loadOptions={promiseOptions}
            inputValue={inputValue}
            placeholder={(address === undefined || address === null)?"Your address...":address}
            onInputChange={(e) => {
              setInputValue(e);
            }}
            onChange={(info) => {
             
            }}
          />
        </Col>
        <Col flex={"50px"}>
          <TextButton style={{ fontSize: 24 }}>
            <AimOutlined />
          </TextButton>
        </Col>
      </Row>
    </div>
  );
}

export default InputLocation;
