// @flow strict

import CollapsedContent from "components/CollapsedContent/CollapsedContent";
import TempTopMenuWidget from "layouts/TempTopMenuWidget/TempTopMenuWidget";
import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import "./PrivacyPage.css";
export const PrivacyPage = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, []);
  const mailToWidget = (emailAddress) => (
    <a href={`mailto://${emailAddress}`}>
      <font color="blue">
        <u> {emailAddress}</u>
      </font>
    </a>
  );
  return (
    <TempTopMenuWidget>
      <MetaTags>
        <title>KalaeCafe | Privacy Policy | Best Deals</title>
        <meta
          name="description"
          content="Read the privacy & policy for KalaeCafe. Get the best deals online.  Our Email address: legal@kalaecafe.com"
        />
        <meta name="keywords" content="Shoppers Online, Best Deals" />
      </MetaTags>
      <div>
        <div className="terms-page">
          <div className="terms-page-title">PRIVACY POLICY</div>
          <div className="terms-page-description">
            <p>
              Privacy policy general content
            </p>
            
          </div>
          <div style={{ marginTop: 50 }}>
            <CollapsedContent title={"Top Freequency Question"}>
              <div className="terms-card terms-body">
                <p>
                  Simple Answer
                </p>
                <p>
                  Full Answer
                </p>
              </div>
            </CollapsedContent>
            
          </div>
        </div>
      </div>
    </TempTopMenuWidget>
  );
};

export default PrivacyPage;
