import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlGetOrderList, urlApproveOrder, urlDeliverOrder, urlPaymentConfirmOrder, urlGetSettings, urlCancelOrder, urlOrderListWithDate, urlGetOrderListDelivered, urlGetOrderListApproved, urlGetOrderListUpcoming } from "./CONSTANTS";


export const apiGetOrderList = () => {
  const url = urlGetOrderList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiOrderListWithDate(data) {
  const url = urlOrderListWithDate;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("start_date", data.start_date);
  formData.append("end_date", data.end_date);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const apiGetOrderListDelivered = (currentPage, pageSize, startDate, endDate) => {
  const url = urlGetOrderListDelivered;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("currentPage", currentPage);
  formData.append("pageSize", pageSize);
  formData.append("startDate", startDate);
  formData.append("endDate", endDate);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiGetOrderListApproved = (currentPage, pageSize, startDate, endDate) => {
  const url = urlGetOrderListApproved;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("currentPage", currentPage);
  formData.append("pageSize", pageSize);
  formData.append("startDate", startDate);
  formData.append("endDate", endDate);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiGetOrderListUpcoming = (currentPage, pageSize, startDate, endDate) => {
  const url = urlGetOrderListUpcoming;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("currentPage", currentPage);
  formData.append("pageSize", pageSize);
  formData.append("startDate", startDate);
  formData.append("endDate", endDate);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const apiGetSettings = () => {
  const url = urlGetSettings;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export function apiApproveOrder(id) {
  const url = urlApproveOrder;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiCancelOrder(id) {
  const url = urlCancelOrder;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiDeliverOrder(id) {
  const url = urlDeliverOrder;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiPaymentConfirmOrder(record) {
  const url = urlPaymentConfirmOrder;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("order_id", record.id);
  formData.append("payment_amount", record.totalBudget);
  formData.append("pay_gateway", record.pay_gateway);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}