import { Col, Row } from "antd";
import TempTopMenuWidget from "layouts/TempTopMenuWidget/TempTopMenuWidget";
import React from "react";
import { MetaTags } from "react-meta-tags";
import HeaderWidget from "./HeaderWidget";
import OrderPage from "./OrderPage/OrderPage";

const DashboardPage = () => {

  return (
    <div>
        <MetaTags>
          <title>Kalae Cafe Chef</title>
          <meta
            name="description"
            content="Welcome to KalaeCafe. Read the terms of services  to understand how they apply to your use of our website."
          />
          <meta name="keywords" content="Best Deals" />
        </MetaTags>
        <Row align={'center'}>
          <Col xs={22} sm={22} md={22} lg={22} xl={22} >
            <OrderPage />
          </Col>
        </Row>
    </div>
  );
};

export default DashboardPage;
