import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Divider } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import "./PrintView.css";
import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import SaleItemWidget from "pages/HomePage/OrderPage/SaleItemWidget/SaleItemWidget";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
export const ComponentToPrint = React.forwardRef((props, ref) => {
  const { dataList, settingData } = props;
  const [staticData, setStaticData] = useState({ totalBudget: 0, grand_total: 0, tax_vat: 0, gst: 0 });

  useEffect(() => {
    let isMounted = true; // Add a flag to keep track of mounting

    let tmpTotalBudget = 0
    dataList.map((childList, index) => {
      childList.map((info, index) => {
        tmpTotalBudget += parseFloat(info.totalBudget)
      })
    })
    let totalBudget = parseFloat(tmpTotalBudget).toFixed(2)
    if (isMounted) { // Check if the component is still mounted
      setStaticData({ ...staticData, totalBudget: totalBudget })
    }

    if (settingData !== undefined) {
      let tax_vat = 0
      let chef_print_header_title = ""
      let chef_print_header_description = ""
      let chef_print_footer_title = ""
      let chef_print_footer_description = ""
      settingData.map((info, index) => {
        if (info.option_name === "tax_vat") {
          tax_vat = parseFloat(info.option_value)
        }
        if (info.option_name === "chef_print_header_title") {
          chef_print_header_title = info.option_value
        }
        if (info.option_name === "chef_print_header_description") {
          chef_print_header_description = info.option_value
        }
        if (info.option_name === "chef_print_footer_title") {
          chef_print_footer_title = info.option_value
        }
        if (info.option_name === "chef_print_footer_description") {
          chef_print_footer_description = info.option_value
        }
      })
      let tmpData = { ...staticData }
      tmpData.chef_print_header_title = chef_print_header_title
      tmpData.chef_print_header_description = chef_print_header_description
      tmpData.chef_print_footer_title = chef_print_footer_title
      tmpData.chef_print_footer_description = chef_print_footer_description
      tmpData.tax_vat = tax_vat
      tmpData.totalBudget = totalBudget
      //tmpData.gst = parseFloat(parseFloat(totalBudget) * parseFloat(tax_vat) / 100).toFixed(2)
      //tmpData.grand_total = parseFloat(totalBudget) + parseFloat(tmpData.gst)
      tmpData.grand_total = totalBudget
      if (isMounted) { // Check if the component is still mounted
        setStaticData(tmpData)
      }

    }

    return () => {
      isMounted = false; // Clean-up work: set the flag to false
    };
  }, [dataList, settingData]);

  const itemUI = (info) => {
    return info.cartList.map((item, index) => {
      return (
        <div key={index}>
          <span className='bold font-14 pdf-init-item-info-header'>{item.enter_qty}x</span>
          <span className='bold font-14 pdf-init-item-info-header' style={{ marginLeft: 10 }}>{item.name}</span>
          <span className='font-15 pdf-init-item-info-header' style={{ marginLeft: 30 }}>${parseFloat(item.total_price).toFixed(2)}&nbsp;{info.payment_currency}</span>
          <div style={{ padding: 5 }}>
            {recipeExtraitemUI(item)}
          </div>
          <div>
            {optionExtraitemUI(item)}
          </div>
          <span className='bold font-14 pdf-init-item-info-header' style={{ display: "block", marginLeft: 10, marginTop: 5 }}>{item.note}</span>
          {info.cartList.length != index + 1 && <div className='item-body-bottom'></div>}
        </div>
      );
    })
  }

  const recipeExtraitemUI = (info) => {
    let dataList = []
    if (info.recipe_extra !== undefined && info.recipe_extra !== null && info.recipe_extra !== "") {
      dataList = JSON.parse(info.recipe_extra)
    }
    return dataList.map((item, index) => {
      return (
        <div key={index}>
          <span className='bold font-14 pdf-init-item-info-header' style={{ marginLeft: 15 }}>{item.name}</span>
          <span className='font-15 pdf-init-item-info-header' style={{ marginLeft: 30 }}>${parseFloat(item.total_price).toFixed(2)}&nbsp;{info.payment_currency}</span>
        </div>


      );
    })
  }

  const optionExtraitemUI = (info) => {
    let dataList = []
    if (info.extraOptionList !== undefined && info.extraOptionList !== null && info.extraOptionList !== "") {
      dataList = JSON.parse(info.extraOptionList)
    }
    return dataList.map((item, index) => {
      return (
        <div key={index} style={{ marginLeft: 20 }}>
          <span className='bold font-14 pdf-init-item-info-header'>{item.name}</span>
          <div style={{ paddingLeft: 8 }}>
            {optionListUI(item)}
          </div>
        </div>
      );
    })
  }

  const optionListUI = (group) => {
    const list = group.optionList
    return list == undefined || list.length == 0 ? <div></div> : list.map((info, index) => {
      return (
        <div key={index}>
          {info.value && <div className='font-15 pdf-init-item-info-header'>{info.name}</div>}
        </div>
      );
    });
  }

  const childItemUi = (info) => {
    return (
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <div className="pdf-init-item-header">
          <div>
            <span>Date : &nbsp;&nbsp; <span className="pdf-init-item-info-header">{info.update_timestamp}</span></span>
            <span style={{ float: 'right' }}>Customer : &nbsp;&nbsp; <span className="pdf-init-item-info-header">{info.customer_name}</span></span>
          </div>
          <div>
            <span>Table : &nbsp;&nbsp; <span className="pdf-init-item-info-header">{info.table_name}</span></span>
            <span style={{ float: 'right' }}>Gateway : &nbsp;&nbsp; <span className="pdf-init-item-info-header">{info.pay_gateway}</span></span>
          </div>
          <div>
            <span>Amount : &nbsp;&nbsp; <span className="pdf-init-item-info-header">${parseFloat(info.totalBudget).toFixed(2)}</span></span>
          </div>
        </div>
        <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
          {itemUI(info)}
        </div>
      </div>
    )
  }

  const subItemUi = (list) => {
    return list.map((info, index) => {
      return <div key={index}>
        {childItemUi(info)}
        {list.length != index + 1 && <Divider
          type="horizontal"
          style={{ height: "1px", borderColor: "#000" }}
          dashed
        />
        }
      </div>
    });
  }


  return (
    <div
      ref={ref}
      style={{ width: 420, padding: 10, fontFamily: "regularFont" }}
    >
      <div>
        <div className="pdf-init-info-title">{staticData.chef_print_header_title}</div>
        <div className="pdf-init-info-container">
          <div>
            <div className="pdf-init-info-description">
              {staticData.chef_print_header_description}
            </div>
          </div>
        </div>
      </div>

      <div className="pdf-comparables-container" style={{ paddingLeft: 20, paddingRight: 20 }}>
        {dataList == undefined || dataList.length == 0 ? <div style={{ margin: "auto" }}>You don't have any Item list</div> : dataList.map((info, index) => {
          return <div key={index}>{subItemUi(info)}
            <Divider
              type="horizontal"
              style={{ height: "1px", borderColor: "#000" }}
              dashed
            />
            <Divider
              type="horizontal"
              style={{ height: "1px", borderColor: "#000", marginTop: "-20px" }}
              dashed
            />
          </div>
        })}
        <div>
          <div className="pdf-init-info-description">
            <div>Total Amount: ${staticData.totalBudget}</div>
            {/* <div>GST {staticData.tax_vat}%: ${staticData.gst}</div> */}
            <Divider
              type="horizontal"
              style={{ height: "1px", borderColor: "#000" }}
              dashed
            />
            <div className="pdf-init-info-footer">Grand Total :  ${staticData.grand_total}</div>
          </div>
        </div>
      </div>

      <div>
        <div className="pdf-init-info-footer">{staticData.chef_print_footer_title}</div>
        <div className="pdf-init-info-container">
          <div>
            <div className="pdf-init-info-description">
              {staticData.chef_print_footer_description}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
});

export const ComponentToMultiPrint = React.forwardRef((props, ref) => {
  const { dataList, settingData } = props;
  const [staticData, setStaticData] = useState({ totalBudget: 0, grand_total: 0, tax_vat: 0, gst: 0 });

  useEffect(() => {
    let isMounted = true; // Add a flag to keep track of mounting

    if (settingData !== undefined) {
      let tax_vat = 0
      let chef_print_header_title = ""
      let chef_print_header_description = ""
      let chef_print_footer_title = ""
      let chef_print_footer_description = ""
      settingData.map((info, index) => {
        if (info.option_name === "tax_vat") {
          tax_vat = parseFloat(info.option_value)
        }
        if (info.option_name === "chef_print_header_title") {
          chef_print_header_title = info.option_value
        }
        if (info.option_name === "chef_print_header_description") {
          chef_print_header_description = info.option_value
        }
        if (info.option_name === "chef_print_footer_title") {
          chef_print_footer_title = info.option_value
        }
        if (info.option_name === "chef_print_footer_description") {
          chef_print_footer_description = info.option_value
        }
      })
      let tmpData = { ...staticData }
      tmpData.chef_print_header_title = chef_print_header_title
      tmpData.chef_print_header_description = chef_print_header_description
      tmpData.chef_print_footer_title = chef_print_footer_title
      tmpData.chef_print_footer_description = chef_print_footer_description
      tmpData.tax_vat = tax_vat
      //tmpData.gst = parseFloat(parseFloat(totalBudget) * parseFloat(tax_vat) / 100).toFixed(2)
      //tmpData.grand_total = parseFloat(totalBudget) + parseFloat(tmpData.gst)
      if (isMounted) { // Check if the component is still mounted
        setStaticData(tmpData)
      }

    }

    return () => {
      isMounted = false; // Clean-up work: set the flag to false
    };
  }, [dataList, settingData]);

  const itemUI = (info) => {
    return info.cartList.map((item, index) => {
      return (
        <div key={index}>
          <span className='bold font-14 pdf-init-item-info-header'>{item.enter_qty}x</span>
          <span className='bold font-14 pdf-init-item-info-header' style={{ marginLeft: 10 }}>{item.name}</span>
          <span className='font-15 pdf-init-item-info-header' style={{ marginLeft: 30 }}>${parseFloat(item.total_price).toFixed(2)}&nbsp;{info.payment_currency}</span>
          <div style={{ padding: 5 }}>
            {recipeExtraitemUI(item)}
          </div>
          <div>
            {optionExtraitemUI(item)}
          </div>
          <span className='bold font-14 pdf-init-item-info-header' style={{ display: "block", marginLeft: 10, marginTop: 5 }}>{item.note}</span>
          {info.cartList.length != index + 1 && <div className='item-body-bottom'></div>}
        </div>
      );
    })
  }

  const recipeExtraitemUI = (info) => {
    let dataList = []
    if (info.recipe_extra !== undefined && info.recipe_extra !== null && info.recipe_extra !== "") {
      dataList = JSON.parse(info.recipe_extra)
    }
    return dataList.map((item, index) => {
      return (
        <div key={index}>
          <span className='bold font-14 pdf-init-item-info-header' style={{ marginLeft: 15 }}>{item.name}</span>
          <span className='font-15 pdf-init-item-info-header' style={{ marginLeft: 30 }}>${parseFloat(item.total_price).toFixed(2)}&nbsp;{info.payment_currency}</span>
        </div>


      );
    })
  }

  const optionExtraitemUI = (info) => {
    let dataList = []
    if (info.extraOptionList !== undefined && info.extraOptionList !== null && info.extraOptionList !== "") {
      dataList = JSON.parse(info.extraOptionList)
    }
    return dataList.map((item, index) => {
      return (
        <div key={index} style={{ marginLeft: 20 }}>
          <span className='bold font-14 pdf-init-item-info-header'>{item.name}</span>
          <div style={{ paddingLeft: 8 }}>
            {optionListUI(item)}
          </div>
        </div>
      );
    })
  }

  const optionListUI = (group) => {
    const list = group.optionList
    return list == undefined || list.length == 0 ? <div></div> : list.map((info, index) => {
      return (
        <div key={index}>
          {info.value && <div className='font-15 pdf-init-item-info-header'>{info.name}</div>}
        </div>
      );
    });
  }

  const childItemUi = (info) => {
    return (
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <div className="pdf-init-item-header">
          <div>
            <span>Date : &nbsp;&nbsp; <span className="pdf-init-item-info-header">{info.update_timestamp}</span></span>
            <span style={{ float: 'right' }}>Customer : &nbsp;&nbsp; <span className="pdf-init-item-info-header">{info.customer_name}</span></span>
          </div>
          <div>
            <span>Table : &nbsp;&nbsp; <span className="pdf-init-item-info-header">{info.table_name}</span></span>
            <span style={{ float: 'right' }}>Gateway : &nbsp;&nbsp; <span className="pdf-init-item-info-header">{info.pay_gateway}</span></span>
          </div>
          <div>
            <span>Amount : &nbsp;&nbsp; <span className="pdf-init-item-info-header">${parseFloat(info.totalBudget).toFixed(2)}</span></span>
          </div>
        </div>
        <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
          {itemUI(info)}
        </div>
        <Divider
          type="horizontal"
          style={{ height: "1px", borderColor: "#000" }}
          dashed
        />
        <Divider
          type="horizontal"
          style={{ height: "1px", borderColor: "#000", marginTop: "-20px" }}
          dashed
        />
        <div>
          <div className="pdf-init-info-description">
            <div>Total Amount: ${parseFloat(info.totalBudget).toFixed(2)}</div>
            {/* <div>GST {staticData.tax_vat}%: ${staticData.gst}</div> */}
            <Divider
              type="horizontal"
              style={{ height: "1px", borderColor: "#000" }}
              dashed
            />
            <div className="pdf-init-info-footer">Grand Total :  ${parseFloat(info.totalBudget).toFixed(2)}</div>
          </div>
        </div>
      </div>
    )
  }

  const subItemUi = (list) => {
    return list.map((info, index) => {
      return <div key={index}>
        {childItemUi(info)}
        {list.length != index + 1 && <Divider
          type="horizontal"
          style={{ height: "1px", borderColor: "#000" }}
          dashed
        />
        }
      </div>
    });
  }


  return (
    <div
      ref={ref}
      style={{ width: 420, padding: 10, fontFamily: "regularFont" }}
    >
      <div className="pdf-comparables-container" style={{ paddingLeft: 20, paddingRight: 20 }}>
        {dataList == undefined || dataList.length == 0 ? <div style={{ margin: "auto" }}>You don't have any Item list</div> : dataList.map((info, index) => {
          return <div key={index}>
            <div>
              <div className="pdf-init-info-title">{staticData.chef_print_header_title}</div>
              <div className="pdf-init-info-container">
                <div>
                  <div className="pdf-init-info-description">
                    {staticData.chef_print_header_description}
                  </div>
                </div>
              </div>
            </div>
            {subItemUi(info)}
            <div>
              <div className="pdf-init-info-footer">{staticData.chef_print_footer_title}</div>
              <div className="pdf-init-info-container">
                <div>
                  <div className="pdf-init-info-description">
                    {staticData.chef_print_footer_description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        })}
      </div>


    </div>
  );
});

const PrintView = (props) => {
  const { dataList, settingData } = props;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      {dataList.length > 0 && (<div style={{ display: "none" }}>
        <ComponentToPrint
          ref={componentRef}
          dataList={dataList}
          settingData={settingData}
        />
      </div>)}
      <CommonButton onClick={() => {
        handlePrint()
      }}><PrinterOutlined /> &nbsp;PRINT</CommonButton>

    </>
  );
};

export default PrintView;
