import React from "react";
import { Row, Col, Modal } from "antd";
const RadiusModal = (props) => {
  const { show, title, onClickOK, onClickCancel, isConfirmDlg, canEscape } =
    props;
  return (
    <Modal
      onCancel={onClickCancel}
      visible={show}
      closable={true}
      maskClosable={canEscape == true}
      title={title}
      onOk={onClickOK}
      className={`custom-dialog ${props.className}`}
      footer={null}
    >
      <div>{props.children}</div>
      <Row align={"middle"} justify={"space-around"} style={{ marginTop: 20 }}>
        {isConfirmDlg !== true && (
          <Col>
            <div
              className="custom-button"
              style={{
                border: "1px solid var(--greyColor)",
                padding: "10px 20px",
                color: "var(--blackColor)",
                width: 100,
                borderRadius: 10,
                textAlign: "center",
              }}
              onClick={onClickCancel}
            >
              Cancel
            </div>
          </Col>
        )}
        <Col>
          <div
            className="custom-button "
            style={{
              border: "1px solid var(--blueColor)",
              padding: "10px 20px",
              color: "var(--whiteColor)",
              width: 100,
              borderRadius: 10,
              textAlign: "center",
              backgroundColor: "var(--blueColor)",
            }}
            onClick={onClickOK}
          >
            OK
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default RadiusModal;
