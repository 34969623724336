import React from "react";
import { Input } from "antd";

const Search = Input.Search;

export const TitleSearch = ({ onSearch, onChange, onPressEnter, placeholder, ...props }) => (
  <div {...props}>
    <Search
      placeholder={placeholder}
      allowClear 
      onSearch={onSearch}
      onChange={onChange}
      onPressEnter={onPressEnter}    
    />
  </div>
);
